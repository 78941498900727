import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./about.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="about" />
    <h1 className="AboutPage--title">about</h1>
    <p className="AboutPage--paragraph">
      Answers to the three most common questions I get about this project and
      this site, and then a copyright notice:
    </p>
    <p className="AboutPage--paragraph">
      For the most part, I think the whole thing is pretty self-explanatory:
      every week, I rewrite one of Shakespeare's sonnets as a limerick. I then
      post it here the following Monday (well, Monday-ish), and on instagram the Monday
      after that, at{" "}<a href="https://instagram.com/limericked" target="_blank" rel="noopener noreferrer" >
        @limericked
      </a> 
    </p>
    <p className="AboutPage--paragraph">
      This project originally started as a way to get out of writing an essay in
      a college course. I claimed that this was a "creative alternative" to
      more-traditional critical analysis, and somehow got away with it. Then, a
      number of years later, as I was looking for a new writing project, I found
      the original five, thought they were fun, and decided to do the other 149.
      I call it a <i>misguided</i> quest because I didn't realize what a commtiment 
      writing (and formatting, and posting) a poem, in my free time, every week, 
      no matter what, for basically three years would be. I highly recommend being 
      misguided in this way. Without it, I never would have started the project in 
      the first place — it would have been too daunting. 
    </p>
    <p className="AboutPage--paragraph">
      I'm not sure what I'll do when I run out of sonnets. Current best
      guesses are I'll either start limericking the news—there are several very
      sharp people doing this already—start doing the rest of Shakespeare's
      collected works, or just stop with the whole limerick shtick and find an entirely different
      writing project.
    </p>
    <p className="AboutPage--paragraph">
      Except for the stuff that Shakespeare wrote, this site and its contents
      are copyright © {new Date().getFullYear()},{" "}
      <a href="https://colemancollins.com" target="_blank" rel="noopener noreferrer" >
        Coleman Collins
      </a>
      . All rights reserved.
    </p>
  </Layout>
)

export default IndexPage
